.letters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 20px auto;
  color: #ed800f;
}

.letters input {
  text-align: center;
  padding: 1px;
  font-size: 1rem;
  width: auto;
  max-width: 2rem;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #ed800f;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.letters input:focus {
  transform: scale(1.2);
}

.aform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("/public/alpha-spag.jpg");
  background-size: cover;
  border-radius: 12px;
  width: 80%;
  height: 10rem;
  margin: 0.1rem auto;
  font-size: 1.25rem;
  font-weight: 900;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

@media screen and (max-width: 612px) {
  .aform {
    width: 80%;
    max-width: 90%;
    height: 12rem;
    font-size: 1rem;
  }
  .letters input {
    width: 1rem;
    margin: 0.5rem 0.05rem;
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 414px) {
  .aform {
    max-width: 100%;
    width: 100%;
    font-size: 1rem;
    text-align: center;
    border-radius: 0;
    height: 35vh;
  }

  .letters {
    gap: 0px; /* Reduce gap on smaller screens */
  }

  .letters input {
    width: auto;
    max-width: 2rem;
  }
}
