
.word {
    display:flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    padding: .5rem;
    margin: 0; 
}

.definition {
    font-size: .75rem;
    text-align: left;
    list-style: disc;
    
}

.item {
    list-style-type: none;
    padding: 0;
}