.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
}
  
.modal {
    position: fixed;
    top: 30vh;
    left: 10vw;
    width: 15rem;
    z-index: 100;
    overflow: hidden;
    background: white;
    box-shadow: 0 2px 8px rgba(0,0,0,0.26);
    border-radius: 10px;
    left: calc(50% - 7.5rem);
   
}
  
.header {
    background: #f57905;
    padding: 1rem;
}

  
.header h2 {
    margin: 0;
    color: white;
}
  
.content {
    padding: 1rem;
}
  
.actions {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
}
  