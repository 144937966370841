* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  background-color: rgb(29, 27, 27);
}

body {
  margin: 0;
}