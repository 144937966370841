.header {
  text-align: left;
  margin: 3rem auto;
  color: #ed800f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header img {
  width: 14vw;
  object-fit: contain;
  background-color: transparent;
  border-radius: 50%;
  margin-right: 1rem;
}

.header h1 {
  font-size: 6vw;
  margin: 0;
}

.header h2 {
  margin: 0;
  font-size: 1.65vw;
  padding-left: 2vw;
}
