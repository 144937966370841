.anag {
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-image: url("/public/alpha-spag.jpg");
  background-size: cover;
  border-radius: 0.25rem;
  width: 20rem;
  height: 8rem;
  margin: 2rem auto;
  /* font-size: 1.25rem;
  font-weight: 900; */
}

.anagram p {
  color: orange;
}

.spaces {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #ed800f;
}

.letters {
  text-transform: uppercase;
  font-size: 1.5rem;
  text-align: center;
  margin: 0 0.5rem;
  color: #ed800f;
}

.mixLetters {
  text-transform: uppercase;
  font-size: 1.5rem;
  text-align: center;
  margin: 0.5rem 0.5rem;
  letter-spacing: 0.75rem;
  overflow-wrap: break-word;
  color: #ed800f;
}

.reset {
  background: rgb(243, 54, 54);
}

.reset:hover {
  background: rgb(176, 9, 9);
}

.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1vw;
}

@media screen and (max-width: 1100px) {
  /* .anag {
    max-width: 70vw;
  } */
}

@media screen and (max-width: 768px) {
  .anag {
    max-width: 90vw;
    font-size: 0.5rem;
    padding: 0.25rem;
  }

  .mixLetters {
    font-size: 1.25rem;
    letter-spacing: 0.25rem;
  }

  .letters {
    font-size: 1.25rem;
    margin: 0 0.1rem;
  }
}
