.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("/public/alpha-spag.jpg");
  background-size: cover;
  border-radius: 0.25rem;
  width: 30rem;
  height: 10rem;
  margin: 0.1rem auto;
  font-size: 1.25rem;
  font-weight: 900;
}

.form label {
  display: block;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 5px;
  border-radius: 0.25rem;
  color: #ed800f;
}

.form input {
  width: 50%;
  padding: 0.5rem;
  border: 1px solid #6e3d01;
  border-radius: 0.25rem;
  background-color: #ffffff;
  color: #ed800f;
  font-size: 1rem;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

@media screen and (max-width: 1100px) {
  .form {
    max-width: 70vw;
  }
}

@media screen and (max-width: 768px) {
  .form {
    max-width: 90vw;
    font-size: 0.5rem;
    padding: 0.25rem;
  }
}

@media screen and (max-width: 514px) {
  .form label {
    font-size: 1rem;
    text-align: center;
  }
}

@media screen and (max-width: 468px) {
  .form input {
    width: 80%;
  }

  .form {
    height: 12rem;
  }
}
