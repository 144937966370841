.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: absolute;
  top: 25vh;
  left: 20vw;
  width: 60vw;
  z-index: 100;
  overflow: hidden;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
}

.header {
  background: #f57905;
  padding: 1rem;
}

.header h2 {
  margin: 0;
  color: white;
}

.content {
  padding: 0.25rem;
  margin: 0.5rem;
}

.content ul {
  padding: 0.25rem;
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 468px) {
  .modal {
    width: 90vw;
    left: 5vw;
    top: 5vh;
  }
}
